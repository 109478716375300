import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';
import { CryptoJsService } from './cryto-js.service';
import { environment } from '../../environments/environment';
import { AuthData, UserProfile } from '../models/modelAuthApi';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private hasSign = new BehaviorSubject<boolean>(false);
  private avatarUrl = new BehaviorSubject<string>('');
 
  BASE_URL = `${environment.apiUrl}${environment.authApi}`;
  jwtHelper = new JwtHelperService();
  constructor(
    private http: HttpClient,
    private cryptoService: CryptoJsService
  ) {}

  AuthAdmin(email: string, password: string): Observable<AuthData> {
    const url = `${this.BASE_URL}/AuthAdmin`;
    return this.http
      .post<AuthData>(url, {
        email,
        password: this.cryptoService.encryptUsingAES256(password),
      })
      .pipe(
        map((resp: AuthData) => {
          localStorage.removeItem('token-tech');
          localStorage.removeItem('userProfile-tech');
          localStorage.removeItem('role');
          if (resp.isSuccess) {
            localStorage.setItem('token-tech', resp.accessToken || '');
            localStorage.setItem(
              'userProfile-tech',
              JSON.stringify(resp.userProfile) || ''
            );
            localStorage.setItem(
              'role',
              JSON.stringify(resp.userProfile.role) || ''
            );

            this.updateStatusHasSign(resp.userProfile.hasSignature);
          }
          return resp;
        })
      );
  }

  loggedIn() {
    const token = localStorage.getItem('token-tech');
    return !this.jwtHelper.isTokenExpired(token || '');
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token-tech');
    localStorage.removeItem('userProfile-tech');
  }

  getUserProfile(): UserProfile {
    if (localStorage.getItem('userProfile-tech')) {
      return JSON.parse(localStorage.getItem('userProfile-tech') || '');
    } else {
      return {
        address: '',
        adminCode: '',
        avatarUrl: '',
        email: '',
        fullName: '',
        id: '',
        phone: '',
        role: '',
        userName: '',
        hasSignature: false,
      };
    }
  }

  checkHasSign() {
    return this.hasSign.asObservable();
  }

  updateStatusHasSign(value: boolean) {
    let data = JSON.parse(localStorage.getItem('userProfile-tech') || '');
    data.hasSignature = value;

    localStorage.setItem('userProfile-tech', JSON.stringify(data) || '');
    this.hasSign.next(value);
  }

  updateAvatarUrl(value: string) {
    let data = JSON.parse(localStorage.getItem('userProfile-tech') || '');
    data.avatarUrl = value;

    localStorage.setItem('userProfile-tech', JSON.stringify(data) || '');
    this.avatarUrl.next(value);
  }


  getAvatarUrl() {
    return this.avatarUrl.asObservable();
  }

 
}
